<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('服务管理.服务订单管理.服务订单id')" prop="orderServiceId" >
        <a-input v-model="form.orderServiceId" :placeholder="$t('服务管理.服务订单管理.请输入服务订单id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.服务者id')" prop="userWaiterId" >
        <a-input v-model="form.userWaiterId" :placeholder="$t('服务管理.服务订单管理.请输入服务者id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.进度百分比')" prop="schedule" >
        <a-input v-model="form.schedule" :placeholder="$t('服务管理.服务订单管理.请输入进度百分比')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.价格')" prop="money" >
        <a-input v-model="form.money" :placeholder="$t('服务管理.服务订单管理.请输入价格')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.方案名称')" prop="name" >
        <a-input v-model="form.name" :placeholder="$t('服务管理.服务订单管理.请输入方案名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.方案资料')" prop="fileIds" >
        <a-input v-model="form.fileIds" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.支付状态')" prop="payStatus" >
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.支付时间')" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.支付金额')" prop="payMoney" >
        <a-input v-model="form.payMoney" :placeholder="$t('服务管理.服务订单管理.请输入支付金额')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.线下支付截图')" prop="payScreenshot" >
        <a-input v-model="form.payScreenshot" :placeholder="$t('服务管理.服务订单管理.请输入内容')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.定金抵扣金额')" prop="deductionMoney" >
        <a-input v-model="form.deductionMoney" :placeholder="$t('服务管理.服务订单管理.请输入定金抵扣金额')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单管理.备注')" prop="remark" >
        <a-input v-model="form.remark" :placeholder="$t('服务管理.服务订单管理.请输入内容')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getServeScheme, addServeScheme, updateServeScheme } from '@/api/order/serveScheme'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        orderServiceId: null,

        userWaiterId: null,

        schedule: null,

        money: null,

        name: null,

        fileIds: null,

        payStatus: 0,

        payTime: null,

        payMoney: null,

        payScreenshot: null,

        deductionMoney: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderServiceId: [
          { required: true, message: '服务订单id,外键{zb_order_service.id}不能为空', trigger: 'blur' }
        ],
        userWaiterId: [
          { required: true, message: '服务者id,外键{zb_user_waiter.id}不能为空', trigger: 'blur' }
        ],
        schedule: [
          { required: true, message: '进度百分比不能为空', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '方案名称不能为空', trigger: 'blur' }
        ],
        fileIds: [
          { required: true, message: '方案资料,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        payStatus: [
          { required: true, message: '支付状态,0-待支付,1-线下支付待审核,2-线下支付,3-已支付不能为空', trigger: 'blur' }
        ],
        payMoney: [
          { required: true, message: '支付金额不能为空', trigger: 'blur' }
        ],
        payScreenshot: [
          { required: true, message: '线下支付截图不能为空', trigger: 'blur' }
        ],
        deductionMoney: [
          { required: true, message: '定金抵扣金额不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        orderServiceId: null,
        userWaiterId: null,
        schedule: null,
        money: null,
        name: null,
        fileIds: null,
        payStatus: 0,
        payTime: null,
        payMoney: null,
        payScreenshot: null,
        deductionMoney: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getServeScheme({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateServeScheme(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addServeScheme(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
