<template>
  <a-modal
    width="80%"
    :title="$t('服务管理.服务订单管理.订单服务详情')"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="服务订单id" prop="orderServiceId">-->
            <!--                <a-input v-model="queryParam.orderServiceId" placeholder="请输入服务订单id" allow-clear />-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="服务者id" prop="userWaiterId">-->
            <!--                <a-input v-model="queryParam.userWaiterId" placeholder="请输入服务者id" allow-clear />-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.服务订单管理.方案名称')" prop="name">
                <a-input
                  v-model="queryParam.name"
                  :placeholder="$t('服务管理.服务订单管理.请输入方案名称')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="进度百分比" prop="schedule">-->
              <!--                  <a-input v-model="queryParam.schedule" placeholder="请输入进度百分比" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="价格" prop="money">-->
              <!--                  <a-input v-model="queryParam.money" placeholder="请输入价格" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->

              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="支付时间" prop="payTime">-->
              <!--                  <a-date-picker-->
              <!--                    style="width: 100%"-->
              <!--                    v-model="queryParam.payTime"-->
              <!--                    format="YYYY-MM-DD HH:mm:ss"-->
              <!--                    allow-clear-->
              <!--                  />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="支付金额" prop="payMoney">-->
              <!--                  <a-input v-model="queryParam.payMoney" placeholder="请输入支付金额" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="定金抵扣金额" prop="deductionMoney">-->
              <!--                  <a-input v-model="queryParam.deductionMoney" placeholder="请输入定金抵扣金额" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
                <!--                  {{ advanced ? '收起' : '展开' }}-->
                <!--                  <a-icon :type="advanced ? 'up' : 'down'" />-->
                <!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:serve/scheme:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button-->
        <!--          type="primary"-->
        <!--          :disabled="single"-->
        <!--          @click="$refs.createForm.handleUpdate(undefined, ids)"-->
        <!--          v-hasPermi="['order:serve/scheme:edit']"-->
        <!--        >-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['order:serve/scheme:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['order:serve/scheme:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <!-- <create-form ref="createForm" @ok="getList" /> -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="data.records"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: '100%' }"
      >
        <!-- 0-未开始,1-待开始/待上传,2-待支付,3-已支付 -->
        <template slot="status" slot-scope="text, record">
          <span v-if="record.status == 0">未开始</span>
          <span v-if="record.status == 1">待开始</span>
          <span v-if="record.status == 2">待支付</span>
          <span v-if="record.status == 3">已支付</span>
        </template>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:serve/scheme:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:serve/scheme:edit']">
            <a-icon type="edit" />{{ $t('服务管理.服务订单管理.详情') }}
          </a>
          <!-- <a-divider type="vertical" v-hasPermi="['order:serve/scheme:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['order:serve/scheme:remove']"> <a-icon type="delete" />删除 </a> -->
          <a-divider type="vertical" v-hasPermi="['order:serve/scheme:remove']" />
          <a @click="$refs.baogaoUploadRefs.handleUpload(record, undefined)" v-hasPermi="['order:serve/scheme:remove']">
            <a-icon type="edit" />{{ $t('服务管理.服务订单管理.上传报告') }}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="data.total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${data.records.length} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <!-- 编辑弹框 -->
      <edit-form ref="createForm" @ok="getList" />

      <!-- 报告弹框 -->
      <baogao-modal ref="baogaoUploadRefs" />
    </a-card>
  </a-modal>
</template>

<script>
import { pageServeScheme, listServeScheme, delServeScheme } from '@/api/order/serveScheme'
// import CreateForm from './modules/CreateForm'
import EditForm from './EditForm.vue'
import { tableMixin } from '@/store/table-mixin'
import BaogaoModal from './Baogao-modal.vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      ///////////;
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        orderServiceId: null,
        userWaiterId: null,
        schedule: null,
        money: null,
        name: null,
        fileIds: null,
        payStatus: null,
        payTime: null,
        payMoney: null,
        payScreenshot: null,
        deductionMoney: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
          width: '180px',
        },
        // {
        //   title: '服务状态',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: { customRender: 'status' },
        // },
        // {
        //   title: '服务者id,外键{zb_user_waiter.id}',
        //   dataIndex: 'userWaiterId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '进度百分比',
          dataIndex: 'schedule',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '价格',
        //   dataIndex: 'money',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '方案名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '方案资料,外键{zb_file.id},多个使用逗号隔开',
        //   dataIndex: 'fileIds',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '支付状态',
          dataIndex: 'payStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待支付'
            } else if (text == 1) {
              return '线下支付待审核'
            } else if (text == 2) {
              return '线下支付'
            } else {
              return '已支付'
            }
          },
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        {
          title: '方案金额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '线下支付截图',
        //   dataIndex: 'payScreenshot',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '定金抵扣金额',
        //   dataIndex: 'deductionMoney',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: '200px',
          fixed: 'right',
        },
      ],
    }
  },
  components: {
    EditForm,
    BaogaoModal,
  },
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk(e) {
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    ///////////////////////////////////////
    /** 查询服务订单方案列表 */
    getList() {
      this.loading = true
      pageServeScheme(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        orderServiceId: undefined,
        userWaiterId: undefined,
        schedule: undefined,
        money: undefined,
        name: undefined,
        fileIds: undefined,
        payStatus: undefined,
        payTime: undefined,
        payMoney: undefined,
        payScreenshot: undefined,
        deductionMoney: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delServeScheme(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'order/order-serve-scheme/export',
            {
              ...that.queryParam,
            },
            `服务订单方案_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'id',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
            width: '180px',
          },
          // {
          //   title: 'status',
          //   dataIndex: 'status',
          //   ellipsis: true,
          //   align: 'center',
          //   scopedSlots: { customRender: 'status' },
          // },

          {
            title: 'progress percentage',
            dataIndex: 'schedule',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'name',
            dataIndex: 'name',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'payStatus',
            dataIndex: 'payStatus',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待支付'
              } else if (text == 1) {
                return '线下支付待审核'
              } else if (text == 2) {
                return '线下支付'
              } else {
                return '已支付'
              }
            },
          },
          {
            title: 'payTime',
            dataIndex: 'payTime',
            scopedSlots: { customRender: 'payTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },
          {
            title: 'Program amount',
            dataIndex: 'payMoney',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'createTime',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: 'operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
            width: '200px',
            fixed: 'right',
          },
        ],
        'zh-CN': [
          {
            title: '编号',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
            width: '180px',
          },
          // {
          //   title: '服务状态',
          //   dataIndex: 'status',
          //   ellipsis: true,
          //   align: 'center',
          //   scopedSlots: { customRender: 'status' },
          // },

          {
            title: '进度百分比',
            dataIndex: 'schedule',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '方案名称',
            dataIndex: 'name',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '支付状态',
            dataIndex: 'payStatus',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待支付'
              } else if (text == 1) {
                return '线下支付待审核'
              } else if (text == 2) {
                return '线下支付'
              } else {
                return '已支付'
              }
            },
          },
          {
            title: '支付时间',
            dataIndex: 'payTime',
            scopedSlots: { customRender: 'payTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },
          {
            title: '方案金额',
            dataIndex: 'money',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '创建时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
            width: '200px',
            fixed: 'right',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>

<style>
</style>